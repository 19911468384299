



















































































import { Collection, Comment, Customer, Fulfillment, Order, OrderItem, Product } from '@/models/internal';
import { Vue, Component } from 'vue-property-decorator';
import { Sync } from 'vuex-pathify';
import { Utility } from '@/tools/Utility';
import { Page } from '@/components/Page.vue';

@Component({
  name: 'Settings',
  components: {
    Page,
  },
})
export default class Settings extends Vue {
  @Sync('context/theme@dark')
  protected isDarkTheme!: boolean;

  protected loading = false;

  protected version: string | null = null;

  public created() {
    this.init();
  }

  /**
   * Clear cached ORM data
   */
  protected async onClearCache() {
    await Promise.all([
      Collection.deleteAll(),
      Comment.deleteAll(),
      Customer.deleteAll(),
      Fulfillment.deleteAll(),
      Order.deleteAll(),
      OrderItem.deleteAll(),
      Product.deleteAll(),
    ]);

    location.reload();
  }

  private async init() {
    this.loading = true;
    this.version = await Utility.fetchVersion();
    this.loading = false;
  }
}
